const burgerMenu = document.querySelector('.header__burger-menu')
const burgerBtn = document.querySelector('.header__burger-btn')
const header = document.querySelector('.header')

burgerBtn.addEventListener('click', function() {
  burgerMenu.classList.toggle('burger-open')
  burgerBtn.classList.toggle('burger-btn-open')

  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
})

document.addEventListener('click', (event) => {
  const isClickInsideMenu = burgerMenu.contains(event.target);
  const isClickOnBurgerButton = burgerBtn.contains(event.target);

  if (!isClickInsideMenu && !isClickOnBurgerButton) {
    burgerMenu.classList.remove('burger-open')
    burgerBtn.classList.remove('burger-btn-open')
  }
});

let lastScrollTop = 0;
// if (window.location.pathname === '/') {
  window.addEventListener('scroll', function() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > lastScrollTop) {
      burgerBtn.style.transform = 'translateY(-101%)';
    } else {
      burgerBtn.style.transform = 'translateY(0)';
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  })
// }

// if (window.location.pathname === '/') {
//   window.addEventListener('scroll', () => {
//     if (window.scrollY <= 200) {
//       header.className = 'header header-fixed';
//     } else {
//       header.className = 'header';
//     }
//   });
// }

function transparentHeader() {
  if (window.location.pathname === '/') {
    header.className = 'header header-fixed';

    window.addEventListener('scroll', () => {
      if (window.scrollY <= 200) {
        header.className = 'header header-fixed';
      } else {
        header.className = 'header';
      }
    })
  } else {
    header.className = 'header';
  }
}

transparentHeader()
